import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';

type CardItemProps = {
  data: any;
};

const ShopItem = (props: CardItemProps) => {
  const { data } = props;
  const { setValue, getValues } = useFormContext();

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        action={
          <Checkbox
            onChange={(_, checked) => {
              setValue(
                'data',
                checked
                  ? [...getValues('data'), data.productId]
                  : getValues('data').filter((id: string) => id !== data.productId)
              );
            }}
          />
        }
        subheader={`Price: ${data.price}`}
      />
      <CardMedia component="img" height="194" image={data.image || ''} alt="Paella dish" />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {data.description || ''}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ShopItem;
