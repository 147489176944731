import { useEffect } from 'react';

import { RouteObject } from 'react-router';
import { useRoutes, useLocation } from 'react-router-dom';

import { AppRouteEnum } from '~/enums/route.enum';
import AboutPage from '~/pages/about';
import DashboardPage from '~/pages/dashboard';
import ErrorPage from '~/pages/error';

export const routerConfig: RouteObject[] = [
  {
    path: '/about',
    element: <AboutPage />,
  },
  {
    path: AppRouteEnum.DASHBOARD,
    element: <DashboardPage />,
  },
  {
    path: '*',
    element: <ErrorPage code={404} />,
  },
];

const AppRouter = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return useRoutes(routerConfig);
};

export default AppRouter;
