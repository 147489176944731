export const shopList = [
  {
    productId: 1,
    price: 1,
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    image: 'https://mui.com/static/images/cards/contemplative-reptile.jpg',
  },
  {
    productId: 2,
    price: 2,
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    image: 'https://mui.com/static/images/cards/contemplative-reptile.jpg',
  },
  {
    productId: 3,
    price: 3,
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    image: 'https://mui.com/static/images/cards/contemplative-reptile.jpg',
  },
  {
    productId: 4,
    price: 4,
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    image: 'https://mui.com/static/images/cards/contemplative-reptile.jpg',
  },
  {
    productId: 5,
    price: 5,
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    image: 'https://mui.com/static/images/cards/contemplative-reptile.jpg',
  },
];
