import React, { useState, useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

const schema = yup.object().shape({
  WEBHOOK_SECRET_KEY: yup.string().required(),
  CHECKOUT_ORIGIN_URL: yup.string().required(),
  END_POINT: yup.string().required(),
  API_PRIVATE_KEY: yup.string().required(),
  MERCHANT_UUID: yup.string().required(),
  KEY_UUID: yup.string().required(),
  TOKEN_UUID: yup.string().required(),
});

const UpdateEnvDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const [envData, setEnvData] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data: any) => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };

      const response = await fetch('/api/env', requestOptions);

      if (!response.ok) {
        throw new Error('Failed to update environment variables');
      }

      const responseData = await response.json();

      console.log('Environment variables updated:', responseData);

      onClose();
    } catch (error) {
      console.error('Error updating environment variables:', error);
    }
  };

  const validateForm = async (data: any) => {
    try {
      await schema.validate(data);
      return true;
    } catch (error) {
      console.error('Form validation error:', error);
      throw error;
    }
  };
  console.log(firstLoad);

  useEffect(() => {
    if (open) {
      fetch('/api/env')
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch environment variables');
          }
          return response.json();
        })
        .then(async (data) => {
          const isValid = await schema.validate(data);
          console.log({ isValid, firstLoad });
          if (isValid && firstLoad) {
            setFirstLoad(false);
            onClose();
          }
          setEnvData(data);
          reset(data);
          if (firstLoad) setFirstLoad(false);
        })
        .catch((error) => {
          console.error('Error fetching environment variables:', error);
          setFirstLoad(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog open={open && !firstLoad} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h5" color="primary" component="p">
          Update Environment Variables
        </Typography>
      </DialogTitle>
      <DialogContent>
        {Object.keys(schema.fields).map((key) => (
          <Controller
            key={key}
            name={key}
            control={control}
            defaultValue={envData?.[key]}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  {...field}
                  fullWidth
                  label={key}
                  multiline={key === 'API_PRIVATE_KEY'}
                  variant="outlined"
                  margin="normal"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              );
            }}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(onSubmit)}
        >
          Update
        </Button>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateEnvDialog;
