import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Navigate, Link as RouterLink, useLocation, generatePath } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import { shopList } from '~/data';
import { env } from '~/env';
import ShopItem from '~/pages/dashboard/shop/ShopItem';

const useStyles = makeStyles()(() => ({
  wrapperList: {
    padding: '24px',
  },
  paymentBtn: {
    textAlign: 'end',
    padding: '16px 0px',
  },
  listContent: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 24,
  },
  totalPrice: {
    marginRight: '8px',
  },
}));

const schema = yup.object({
  data: yup.array(),
});

interface FormValues extends yup.InferType<typeof schema> {}

const ListItem = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.listContent}>
      {shopList.map((item) => (
        <ShopItem key={item.productId} data={item} />
      ))}
    </Box>
  );
};

const PaymentAction = ({ onPayment }: any) => {
  const { classes } = useStyles();
  const data = useWatch({ name: 'data' });
  const mount = data.reduce((total: number, item: any) => {
    const e = shopList.find((e) => e.productId === item);
    return total + (e?.price || 0);
  }, 0);

  return (
    <Box className={classes.paymentBtn}>
      {!!mount && <span className={classes.totalPrice}>{mount}</span>}
      <Button color="primary" variant="contained" size="large" onClick={onPayment} disabled={!mount}>
        Payment
      </Button>
    </Box>
  );
};

const ShopList = () => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const form = useForm<FormValues>({
    defaultValues: {
      data: [],
    },
    resolver: yupResolver(schema),
  });

  const onPayment = async ({ data }: any) => {
    try {
      const selectItem = shopList.filter((item) => data.includes(item.productId));

      const paymentDetails = {
        amount: selectItem.reduce((total, item) => total + item.price, 0), // Example calculation
        currency: 'USD', // Replace with the correct currency
        description: 'Payment for selected items',
        items: selectItem.map((item) => ({ productId: item.productId, price: item.price })),
      };

      const response = await fetch('/api/create-payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentDetails),
      });

      console.log(response);

      if (!response.ok) {
        const errorDetails = await response.text();
        throw new Error(`Request failed with status ${response.status}: ${errorDetails}`);
      }

      const responseData = await response.json();

      // Extract the paymentId from the response

      const redirectUrl = responseData.paymentUrl;

      if (redirectUrl) window.location.href = redirectUrl;
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <FormProvider {...form}>
      <div className={classes.wrapperList}>
        <PaymentAction onPayment={form.handleSubmit(onPayment, (error) => console.log(error))} />
        <ListItem />
      </div>
    </FormProvider>
  );
};

export default ShopList;
