import './index.css';
import './i18n';
import './utils/yup.util';
import './config/dataGrid';
import 'react-quill/dist/quill.snow.css';
import 'moment-timezone';

import React from 'react';

import { createRoot } from 'react-dom/client';

import App from './App';

// const postal_code = require('japan-postal-code');

// function importAll(r: __WebpackModuleApi.RequireContext) {
//   r?.keys().forEach(r);
// }

// console.log(require.context('japan-postal-code/zipdata', true, /\.js$/));
// importAll(require.context('japan-postal-code/zipdata', true, /\.js$/));

// postal_code.setJsonDataUrl('/japan-postal-code/zipdata/zip-');

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
