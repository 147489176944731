import { useEffect, useState } from 'react';

import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import UserLayout from '~/components/app-layout/user-layout';
import UpdateEnvDialog from '~/components/update-env-dialog';
import { AppRouteEnum } from '~/enums/route.enum';
import ShopList from '~/pages/dashboard/shop';

const useStyles = makeStyles()((theme) => ({
  card: {
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
    height: '100%',
  },

  cardTitle: {
    minHeight: 64,
  },
  outstandingBalanceInfo: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      rowGap: theme.spacing(2),
    },
  },
  applicationInfo: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    },
    gap: theme.spacing(2),
  },
}));

const DashboardPage = () => {
  const { t } = useTranslation();
  const { classes } = useStyles(undefined, { props: {} });
  const [open, setOpen] = useState(true);

  useEffect(() => {
    document.title = t('dashboard');
  }, [t]);

  return (
    <UserLayout>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Link
            component={RouterLink}
            to={AppRouteEnum.DASHBOARD}
            color="text.primary"
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Shop List
          </Link>
        </Breadcrumbs>
        <Button onClick={() => setOpen(true)}>Set up Env</Button>
      </div>
      <div style={{ width: '100%' }}>Shop List</div>
      <ShopList />
      <UpdateEnvDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </UserLayout>
  );
};

export default DashboardPage;
